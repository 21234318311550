/*Global CSS */
@import url("variable.css");

html {
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body {
  padding: 0 !important;
  margin: 0;
}
#container-main-id {
  background-color: var(--brand) !important;
  padding: var(--gap) !important;
  font-family: var(--ubunturegular);
  overflow-x: hidden;
  font-weight: 400;
  margin: 0 !important;
}

.brandbgcolog {
  background-color: var(--brand);
}

.whitebgcolor {
  background-color: var(--white);
  color: var(--black);
}

.blackbgcolor {
  background-color: var(--black);
}

.blackcolor {
  color: var(--black);
}

.dargraybgcolor {
  background-color: var(--darlgray);
}

.containerfluid {
  width: 100%;
}

.pt-5vh {
  padding-top: 30px;
}

/**Header -- START**/
header.hide-scrollbar.headerbox {
  padding-right: 77px;
}

.headerbox.scrolled.hide-scrollbar.headerbox {
  padding-right: 47px;
}

.headerbox {
  padding: 0 60px;
  background: transparent !important;
  box-shadow: none;
  z-index: 99 !important;
  position: absolute !important;
  box-shadow: none !important;
  width: 100%;
  margin-top: 40px;
  left: 0 !important;
}

html[style="overflow: hidden;"] header.headerbox {
  z-index: 9999 !important;
}

.headerbox>div {
  margin-left: var(--gap) !important;
  margin-right: var(--gap) !important;
  margin: 0 !important;
  align-items: baseline;
}

.headerbox>div {
  padding: 24px 15px;
  display: inline-flex;
  align-items: end;
}

.headerbox.scrolled {
  background-color: var(--brand) !important;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 10px;
  margin: 0;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter:blur(10px);
  position: fixed !important;
  animation: slideDown 0.25s ease-out;
  z-index: 99 !important;
}

@keyframes slideDown {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0);
  }
}

.headerbox.scrolled>div {
  padding-bottom: 0;
  align-items: center;
  padding-top: 10px;
}

.headerbox.scrolled>div a {
  padding-top: 0;
}

.BRLogo {
  position: inherit;
  z-index: 1000;
  display: inline-block;
}

.BRLogo img {
  width: 222px;
  transition: width 0.05s ease-in-out;
}

.headerbox.scrolled img {
  width: 150px;
}

/**Top Navigation - START*/

.TopNavigation {
  align-items: baseline;
}

.TopNavigation a {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  background-color: transparent;
  display: inline-block;
  text-decoration: none;
  padding-left: 30px;
  font-family: var(--ubuntumedium);
  padding-bottom: 10px;
  /* margin-top: -10px; */
}

.TopNavigation a.active-menu::after {
  width: 100%;
}

.scrolled .TopNavigation a {
  margin-top: 0px;
  padding-bottom: 0px;
}

.TopNavigation a:after {
  content:"";
  display: block;
  width: 0;
  height: 2px;
  background: var(--white);
  transition: width 0.3s;
}

/* For users who prefer reduced motion */
@media (prefers-reduced-motion: reduce) {
  .TopNavigation a:after {
    transition: none; /* Disable transition for reduced motion preference */
  }
}

/* Hover effect for normal users (no reduced motion preference) */
.TopNavigation a:hover:after {
  width: 100%;
}
/* .TopNavigation a:after {
  content:"";
  display: block;
  width: 0;
  height: 2px;
  background: var(--white);
  transition: width 0.3s;
}

.TopNavigation a:hover:after {
  width: 100%;
} */



.mobilemenu ul {
  margin-left: 12vh;
  width: 100%;
  font-weight: 500;
}

.mobilemenu ul a {
  color: var(--white);
  text-transform: uppercase;
  text-decoration: none;
  font-family: var(--ubunturegular);
}

.mobilemenu .MuiDrawer-paper {
  justify-content: left;
}

.mobilemenu ul a:hover,
.mobilemenu ul a:hover h6 {
  color: var(--brand);
}

.cd-nav-trigger {
  height: 44px;
  overflow: hidden;
  right: 20px;
  text-indent: 100%;
  /* top: 60px; */
  white-space: nowrap;
  width: 44px;
  z-index: 2000;
  position: relative;
  /* position: fixed; */
}

.cd-nav-trigger:before,
.cd-nav-trigger:after,
.cd-nav-trigger .cd-icon:before,
.cd-nav-trigger .cd-icon:after {
  content:"";
}

.cd-nav-trigger .cd-icon {
  /* icon created in CSS */
  position: absolute;
  left: 50%;
  top: 50%;
  bottom: auto;
  right: auto;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  display: inline-block;
  width: 40px;
  height: 2px;
  background-color: #ffffff;
  z-index: 10;
}

.cd-nav-trigger .cd-icon:before,
.cd-nav-trigger .cd-icon:after {
  /* upper and lower lines of the menu icon */
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  /* Force Hardware Acceleration in WebKit */
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  /* apply transition to transform property */
  -webkit-transition: -webkit-transform 0.3s;
  -moz-transition: -moz-transform 0.3s;
  transition: transform 0.3s;
}

.cd-nav-trigger .cd-icon:before {
  -webkit-transform: translateY(-8px) rotate(0deg);
  -moz-transform: translateY(-8px) rotate(0deg);
  -ms-transform: translateY(-8px) rotate(0deg);
  -o-transform: translateY(-8px) rotate(0deg);
  transform: translateY(-8px) rotate(0deg);
}

.cd-nav-trigger .cd-icon:after {
  -webkit-transform: translateY(8px) rotate(0deg);
  -moz-transform: translateY(8px) rotate(0deg);
  -ms-transform: translateY(8px) rotate(0deg);
  -o-transform: translateY(8px) rotate(0deg);
  transform: translateY(8px) rotate(0deg);
}

.cd-nav-trigger:before,
.cd-nav-trigger:after {
  /* 2 rounded colored backgrounds for the menu icon */
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  height: 100%;
  width: 100%;
  /* Force Hardware Acceleration in WebKit */
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  transition-property: transform;
}

.cd-nav-trigger:before {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  -webkit-transition-duration: 0.1s;
  -moz-transition-duration: 0.1s;
  transition-duration: 0.1s;
  -webkit-transition-delay: 0.2s;
  -moz-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.cd-nav-trigger:after {
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  -webkit-transition-duration: 0s;
  -moz-transition-duration: 0s;
  transition-duration: 0s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
}

.cd-nav-trigger.close-nav:before {
  /* user clicks on the .cd-nav-trigger element - 1st rounded background disappears */
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
}

.cd-nav-trigger.close-nav:after {
  /* user clicks on the .cd-nav-trigger element - 2nd rounded background appears */
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-delay: 0.4s;
  -moz-transition-delay: 0.4s;
  transition-delay: 0.4s;
}

.cd-nav-trigger.close-nav .cd-icon {
  /* user clicks on the .cd-nav-trigger element - transform the icon */
  background-color: rgba(255, 255, 255, 0);
}

.cd-nav-trigger.close-nav .cd-icon:before,
.cd-nav-trigger.close-nav .cd-icon:after {
  background-color: white;
}

.cd-nav-trigger.close-nav .cd-icon:before {
  -webkit-transform: translateY(0) rotate(45deg);
  -moz-transform: translateY(0) rotate(45deg);
  -ms-transform: translateY(0) rotate(45deg);
  -o-transform: translateY(0) rotate(45deg);
  transform: translateY(0) rotate(45deg);
}

.cd-nav-trigger.close-nav .cd-icon:after {
  -webkit-transform: translateY(0) rotate(-45deg);
  -moz-transform: translateY(0) rotate(-45deg);
  -ms-transform: translateY(0) rotate(-45deg);
  -o-transform: translateY(0) rotate(-45deg);
  transform: translateY(0) rotate(-45deg);
}

.cd-nav-trigger .cd-icon:after {
  transform: translateY(8px) rotate(0deg);
}

.hero_landing.hero {
  height: calc(100vh - 80px) !important;
  align-items: flex-end;
  display: flex;
}

.hero {
  border-radius: var(--border-radius);
  height: 300px !important;
}

.overlay {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  background: url("./assets/Images/header/pix.png") repeat;
  background-repeat: repeat;
  z-index: 2;
}

.hero-content {
  background-color: rgb(166 43 22 / 33%);
  z-index: 3;
  position: relative;
  color: var(--white);
  border-radius: var(--border-radius);
  margin-top: 40vh;
  text-align: center;
  padding: 46px;
  width: 83%;
  margin: 0 auto;
  margin-bottom: 144px;
}

.bannerTextCenter .hero-content {
  background-color: rgb(166 43 22 / 33%);
  z-index: 3;
  position: relative;
  color: var(--white);
  margin-top: 34vh;
  text-align: center;
  float: none;
  padding: 7vh 4vh;
  border-radius: var(--border-radius);
  margin-right: 13vh;
  margin-left: 11vh;
  width: auto;
}

.hero-content h1 {
  font-size: 63px;
  /**for Big screen*/
  font-weight: 500;
}

.hero-content p {
  font-size: 34px;}

.hero-content h1 b {
  font-weight: bold;
}

.toptechnologies {
  position: absolute;
  bottom: 10vh;
  left: 0;
  width: calc(100% - 107px);
  display: flex;
  justify-content: space-between;
  padding-left: 50px;
  padding-right: 50px;
  z-index: 3;
  gap: 30px;
}

.technologiesItem {
  display: flex;
  align-items: center;
  gap: 10px;
}

.technologyName {
  font-size: 26px;
  line-height: 3.5vh;
  color: #fff;
  white-space: nowrap;
  gap: 10px;
}

.technologyImg {
  width: 50px;
  display: flex;
  flex-direction: column;
}

.technologyImg img {
  width: 100%;
}

.viewallcustomerstories {
  display: none;
}

.viewAllTextAdded .viewallcustomerstories {
  display: inline-block;
  font-size: 16px;
  float: right;
  color: #fff;
}

.viewAllTextAdded .custom-arrow {
  position: absolute;
  top: 50vh;
  right: -89px;
  background: transparent;
  transform: translateY(-50%);
  color: white;
  border: none;
  cursor: pointer;
  padding: 10px;
  z-index: 99999;
  background: #fff;
  border-radius: 100%;
  color: #000;
  height: 33px;
}

.viewAllTextAdded .custom-arrow.left {
  right: inherit;
  left: -89px !important;
}

.CustomerStories .react-multi-carousel-list {
  overflow: visible !important;
  position: initial;
  margin: 0 -15px;
}

/* CustomArrows.css */
.carousel-arrows {
  position: relative;
}

.custom-arrow {
  background: #0000;
  border: none;
  color: #fff;
  cursor: pointer;
  left: inherit !important;
  padding: 0;
  position: absolute;
  right: 0;
  top: -11px;
  transform: translateY(-50%);
  z-index: 9;
}

.custom-arrow:hover {
  opacity: 1 !important;
  /* Ensure hover opacity is always 1 even if it's disabled */
}

.custom-arrow.disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.custom-arrow.left {
  right: 37px;
  left: 10px;
}

.custom-arrow svg {
  font-size: 2rem;
}

.heading {
  font-family: var(--ubunturegular) !important;
  font-weight: normal !important;
  font-size: var(--heading) !important;
  margin-bottom: 10px !important;
}

.secondheading {
  font-family: var(--ubuntumedium) !important;
  font-weight: 500 !important;
  font-size: var(--secondheading) !important;
  margin-bottom: 24px !important;
}

.whitecolor {
  color: var(--white);
}

.content {
  font-size: var(--bodycontentfontsizelg) !important;
  line-height: 34px !important;
  font-family: var(--ubunturegular) !important;
  font-weight: normal !important;
}

.outerbox {
  color: var(--white);
  padding: 30px 60px;
  margin: var(--spacebetweenlg) 0;
  border-radius: var(--border-radius);
}

.whitebox {
  color: var(--black);
  padding: 30px 60px;
  margin: var(--spacebetweenlg) 0;
  border-radius: var(--border-radius);
  /* width: calc(100% - 120px) !important; */
  background-color: var(--white);
}

.graybox {
  color: var(--black);
  padding: 30px 60px;
  margin: var(--spacebetweenlg) 0;
  border-radius: var(--border-radius);
  width: calc(100% - 120px) !important;
  background-color: #f8f8f8;
}

.contentbox.whitebox {
  width: calc(100% - 66px) !important;
}

.w100,
.whitebox.w100,
.contentbox.whitebox.w100 {
  width: 100% !important;
}

.OurServices,
.OurTechnologies {
  padding: var(--spacebetweenlg) 0;
}

/*Cutomer Stories css Start**/
.my-own-custom-container {
  padding-top: var(--spacebetweenlg);
  position: initial;
}

/* .my-own-custom-container ul { */
/* gap: var(--gap); */
/* } */
.my-own-custom-container ul li {
  aspect-ratio: 3 / 4;
  height: 66vh;
  /* cursor: pointer; */
  /* overflow: hidden;
  border-radius: var(--border-radius); */
}

.my-own-custom-container ul li:hover img {
  -webkit-transform: scale(1.25);
  transform: scale(1.25);
}

.csiteam {
  height: 100%;
  display: block;
  margin: 0 15px;
  box-sizing: border-box;
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  cursor: pointer;
}

.linear-bg:after {
  background: -webkit-gradient(linear, left top, right top, from(#000), to(transparent));
  background: linear-gradient(360deg, #000, transparent);
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  content:"";
  top: 0;
  z-index: 1;
  opacity: 0.75;
}

.my-own-custom-container ul li:hover .linear-bg:after {
  opacity: 0.9;
}

.csImage {
  height: 100%;
  width: 100%;
}

.csImage img {
  object-fit: cover;
  width: 100%;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  -webkit-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
  position: absolute;
  top: 0;
  height: 100%;
}

.csDescription {
  position: absolute;
  bottom: 24px;
  right: 24px;
  left: 24px;
  width: 88%;
  z-index: 1;
}

.csDescription .csTitle {
  font-family: var(--ubuntulight) !important;
  font-weight: normal !important;
  font-size: var(--heading) !important;
  margin-bottom: 24px !important;
  line-height: 44px !important;
  padding-bottom: 2px;  
  text-overflow: ellipsis;  
  width: 100%;
  word-wrap: break-word;
  color: #fff;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;  
  overflow: hidden;

}

.csreadmore {
  color: var(--white);
  line-height: 1.5rem;
  letter-spacing: 0.15em;
  display: inline-flex;
  gap: 0 10px;
  text-transform: uppercase;
  left: 0;
  margin-top: var(--spacebetweenlg);
  flex-wrap: wrap;
  width: 149px;
  font-weight: normal;
  font-size: 16px;
}

.csreadmore:after {
  content:"";
  display: block;
  width: 0;
  height: 1px;
  background: var(--brand);
  transition: width 0.3s;
}

.my-own-custom-container ul li:hover .csreadmore:after {
  width: 100%;
}

.cardlink {
  display: block;
  width: 100%;
  border-radius: var(--border-radius);
  overflow: hidden;
  cursor: pointer;
  aspect-ratio: 3 /3;
}

.OurServicescard {
  padding: 0 !important;
  margin: 0 !important;
}

.OurServicescard {
  height: 100%;
  border-radius: 20px !important;
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 1.5rem;
  -webkit-transition: 0.7s;
  transition: 0.7s;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  margin-top: 2rem;
  background-color: transparent;
}

.bggradint:before {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  content:"";
  z-index: 1;
  opacity: 0.2;
  background: #3b3d4a;
  mix-blend-mode: multiply;
  border-radius: 8px;
  -webkit-filter: saturate(20%);
  filter: saturate(20%);
  border-radius: 2px;
}

.bggradint:after {
  background: -webkit-gradient(linear, left bottom, left top, from(transparent), to(#000)) !important;
  background: linear-gradient(15deg, #000, transparent) !important;
  border-radius: 8px;
  opacity: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  content:"";
  top: 0;
  z-index: 1;
  opacity: 0.75;
  border-radius: 2px;
}

.OurServicesImg {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
}

.cardlink:hover .bggradint:before,
.cardlink:hover .bggradint:after {
  background: linear-gradient(30deg, #000, transparent) !important;
  -webkit-filter: saturate(80%);
  filter: saturate(80%);
  opacity: 0.74;
}

.OurServicesText {
  z-index: 2;
  position: absolute;
  font-size: 32px !important;
  padding: var(--spacebetweenlg);
  color: var(--white);
  bottom: 42px;
}

.processImage {
  width: 80%;
  margin: var(--spacebetweenlg) auto 0 auto;
  text-align: center;
}

.Technologies ul {
  gap: 0;
  
}

.Technologies {
  padding-top: 20px;
}

.Technologies .carousel-item {
  text-align: center;
}

.Technologies ul>li>div>img {
  filter: grayscale(100%);
  height: 38px;
}

.Technologies ul>li>div:hover img {
  filter: grayscale(0);
}

.footer {
  color: var(--white);
  border-radius: var(--border-radius);
}

.joinourteam {
  padding: 30px 9vh 0 9vh;
  margin-bottom: 0;
}

.jointeamheading {
  font-family: var(--ubuntumedium) !important;
  font-size: 56px !important;
  font-weight: normal;
  margin: 30px !important;
}

.joinourteam a {
  margin: 3vh;
  margin-bottom: 30px;

  padding: 1.3vh 5vh;
  text-transform: none;
  font-size: var(--bodycontentfontsizelg);
  background-color: var(--brand);
  color: var(--white);
  border: solid 1px transparent;
  font-family: var(--ubunturegular);
  font-weight: normal;
}

.joinourteam a:hover {
  background: transparent;
  border-color: var(--white);
}

.graydivider {
  background-color: rgba(255, 255, 255, 0.7);
  margin: 24px 0 !important;
}

.orangedivider {
  background-color: rgba(212, 80, 58, 0.6);
  margin: 24px 0 !important;
}

.Footerbrlogo {
  cursor: pointer;
}

.footerlinks {
  margin: 0 9vh;
}

.footerlinks ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0 !important;
}

.footerlinks ul li {
  padding-right: 0px;
  white-space: nowrap;
  margin-right: 15%;
  line-height: 18px;

}

.ourservice {
  /* width: 20%; */
}

.vertilcal {
  width: 32%;
}

.footerlinks ul li ul li {
  margin-right: 22%;
}

.footerlinks ul li:last-child {
  margin-right: 0 !important;
}

.footerlinks b {
  margin-bottom: 10px;
  display: inline-block;
  font-size: 14px;
  font-family: var(--ubuntubold);
  text-transform: uppercase;
}

.footerlinks a {
  color: var(--white);
  display: inline-block;
  text-decoration: none;
  font-size: 14px;
  font-family: var(--ubunturegular);
  margin-bottom: 10px !important;
  cursor: pointer;
}

.footerlinks a:hover {
  text-decoration: underline;
}

.FooterBottom {
  display: flex;
  padding-bottom: var(--spacebetweenlg);
  margin-top: -8px;
}

.Footerbrlogo {
  width: 80px;
}

.Footerbrlogo a {
  margin-bottom: 0 !important;
}

.Footerbrlogo img {
  width: 60px;
}

.FooterbottomLeft {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin-bottom: 14px;
}

.socialarea {
  display: flex;
  width: 50%;
  justify-content: end;
  align-items: center;
  gap: 10px;
}

.footerlastlinks {
  /* line-height: 0; */
  width: 100%;
  line-height: 18px;
}

.footerlastlinks a {
  font-size: 16px;
  font-family: var(--ubuntumedium);
  line-height: 18px;
  margin-bottom: 5px !important;
}

.footerlastlinks span,
.footerlastlinks span a {
  font-family: var(--ubunturegular);
  font-size: 14px;
}

.saprator {
  margin: 0 5px;
  font-weight: 400 !important;
}

.footerlastlinks span {
  /* margin: 0 1vh; */
}

.footerlastlinks span a {
  text-decoration: underline;
}

/* Our Work page   */
.SelectClass {
  margin-left: 10px;
  margin-right: 20px;
  width: 200px !important;
  height: 30px !important;
  border-bottom: solid 1px #ddd !important;
}

.dropdownSection {
  position: absolute;
  right: 150px;
  width: 200px;
  height: 120px;
}

.FooterLinkTitle {
  position: relative;
}

.FooterLinkTitle::after {
  display: none !important;
  content:"";
  position: absolute;
  display: inline-block;
  width: 1em;
  height: 1em;
  --svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23000' d='M12 14.789L15.788 11l-.713-.688L12 13.387l-3.075-3.075l-.714.688zM12.003 21q-1.867 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709M12 20q3.35 0 5.675-2.325T20 12t-2.325-5.675T12 4T6.325 6.325T4 12t2.325 5.675T12 20m0-8'/%3E%3C/svg%3E");
  background-color: currentColor;
  -webkit-mask-image: var(--svg);
  mask-image: var(--svg);
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
  top: -5px;
  right: 2px;
  font-size: 30px;
}

.FooterLinkTitle.active::after {
  --svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23000' d='M8.925 13.689L12 10.612l3.075 3.075l.713-.688L12 9.211L8.211 13zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709M12 20q3.35 0 5.675-2.325T20 12t-2.325-5.675T12 4T6.325 6.325T4 12t2.325 5.675T12 20m0-8'/%3E%3C/svg%3E");
}

/*Our Work Page Style START*/
.ourworkiteams>.workcard .casestudies button {
  background-color: transparent !important;
}

.ourworkiteams>.workcard .casestudies:hover button {
  background-color: #fff !important;
}

.ourworkiteams>.workcard .casestudies:hover button:hover {
  border-color: #ffff !important;
  color: #ffff !important;
  background-color: #d5452b !important;
}

.innerpagecontainer {
  padding: var(--spacebetweenlg) 0;
  border-radius: var(--border-radius);
}


.casestudy_container {
  margin: 16px 0;
}


.cs_row2 {
  padding: 0 !important;
  margin: 30px 0;
}

.MuiButton-colorPrimar {
  background-color: var(--brand);
}

.landing_content {
  margin-left: 0;
  margin-right: 0;
  text-align: center;
}

.career_container {
  padding: 0;
  margin: var(--spacebetweenlg) 0;
}

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  background: rgb(255 255 255 / 67%);
  color: #000;
  border: none;
  border-radius: 50%;
  width: 42px;
  height: 42px;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 10px;

}

.arrow:hover {
  background-color: #ffff;
}

/*Overide inner page Style*/
.contentwidth {
  max-width: 1140px;
  margin-left: auto !important;
  margin-right: auto !important;
}

.popupclosebtn:hover {
  background-color: black !important;
}

.popupclosebtn {
  font-size: 2rem;
}

/*----START Responsive*/
@media only screen and (max-width: 1680px) {
  .footerlinks ul li {
    margin-right: 11%;
  }
}

@media only screen and (max-width: 1600px) {
  .hero-content {
    margin-bottom: 110px;
  }

  .csDescription .csTitle {
    font-size: 28px !important;
    line-height: 38px !important;
    color: #fff;
  }

  .TopNavigation a {
    color: #fff;
    font-size: 16px;
  }

  .technologyName {
    font-size: 24px;
  }
}

@media only screen and (max-height: 900px) {
  .hero-content {
    margin-bottom: 110px;
  }

}


@media only screen and (max-height: 768px) {
  .hero-content {
    margin-bottom: 110px;
  }

}

@media only screen and (max-width: 1480px) {
  .footerlinks ul li {
    margin-right: 8.4%;
  }

  .footerlinks ul li ul li {
    margin-right: 15%;
  }

  .hero-content h1 {
    font-size: 58px;
  }

}

@media only screen and (max-width: 1400px) {

  .hero-content h1 {
    font-size: 52px;
  }

  .hero-content p {
    font-size: 32px;
  }

}


@media only screen and (max-width: 1366px) {
  :root {
    --heading: 28px;
    --bodycontentfontsizelg: 18px;
    --secondheading: 24px;
    --spacebetweenlg: 16px;
  }

  .BRLogo img {
    width: 70%;
  }

  .footerlinks ul li {
    margin-right: 11%;
  }


  .hero-content h1 {
    font-size: 52px;
  }

  .OurServicesText {
    bottom: 32px;
    line-height: 34px !important;
    font-size: 28px !important;
  }

  .hero-content p {
    font-size: 28px;
  }

  .TopNavigation a {
    color: #fff;
    font-size: 14px;
    font-family: var(--ubunturegular);
    font-weight: normal;
  }

  .technologyName {
    font-size: 18px;
  }

  .csreadmore {
    font-size: 14px;
  }

  .content {
    line-height: 24px !important;
  }

  .Technologies ul>li>div>img {
    /* width: 85% !important; */
    /* height: 24px !important; */
  }

  .jointeamheading {
    font-size: 40px !important;
    line-height: 0 !important;
  }

  .footerlinks b,
  .footerlinks a,
  .footerlastlinks a,
  .footerlastlinks span,
  .footerlastlinks span,
  .footerlastlinks span a {
    font-size: 12px;
  }

  .Footerbrlogo {
    width: 64px;
    margin-right: 6px;
  }
}

@media only screen and (max-width: 1280px) {
  
    :root {
      --heading: 26px;
      --bodycontentfontsizelg: 16px;
      --secondheading: 22x;
      --spacebetweenlg: 16px;
    }
  .content-responsive {
    padding-right: 0px;
  }
  
  .csDescription .csTitle {
    font-size: 26px !important;
    line-height: 34px !important;
    color: #fff;
}
.OurServicesText {
  bottom: 32px;
  line-height: 34px !important;
  font-size: 26px !important;
}
  .hero-content h1 {
    font-size: 50px;
  }

  .footerlinks ul li ul li {
    margin-right: 22%;
  }

  .footerlinks ul li {
    margin-right: 10%;
  }


  .Technologies ul>li>div>img {
    height: 32px;
  }
}

@media only screen and (max-width: 1152px) {
  .hero-content h1 {
    font-size: 45px;
  }

  .hero-content p {
    font-size: 24px;
  }

  .outerbox,
  .whitebox {
    padding: 30px;
  }

  .OurServicesText {
    line-height: 32px !important;
    bottom: 16px;
    font-size: 24px !important;
  }

  .OurServices,
  .OurTechnologies {
    padding: 0;
  }

  .csDescription .csTitle {
    font-size: 20px;
    line-height: 30px;
    color: #fff;
  }

  .BRLogo img {
    width: 60%;
  }

  .footerlinks ul li {
    margin-right: 8%;
  }

  .footerlinks ul li ul li {
    margin-right: 11%;
  }
}

@media screen and (max-width: 1024px) {
  :root {
    --gap: 24px;
  }

  .hero {
    height: calc(100vh - 48px);
  }

  .headerbox {
    padding: 0 var(--gap);
  }

  .headerbox>div,
  .outerbox,
  .joinourteam,
  .whitebox {
    padding: var(--gap);
  }

  .contentbox.whitebox {
    width: calc(100% - 16px) !important;
  }

  .contentbox {
    padding: 8px !important;
    width: calc(100% - 16px) !important;
  }

  .joinourteam {
    margin-bottom: 0 !important;
  }

  .toptechnologies,
  .headerbox.scrolled {
    padding-left: var(--gap);
    padding-right: var(--gap);
  }

  .jointeamheading {
    font-size: 30px !important;
    line-height: 1.2 !important;
    margin-bottom: 0 !important;
  }

  .graydivider {
    margin: var(--gap) 0 !important;
  }

  .footerlinks {
    margin: 0 var(--gap);
  }

  .footerlinks ul {
    padding: 0;
  }

  .socialarea {
    width: auto;
  }

  .toptechnologies {
    flex-wrap: wrap;
    width: 100%;
  }

  .technologiesItem {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 25%;
  }

  .graybox {
    padding: 8px;
    width: calc(100% - 16px) !important;
  }

  .carousel-arrows {
    top: 30px;
  }

  .OurServicesText {
    font-size: 24px !important;
    line-height: 34px !important;
  }

  .my-own-custom-container {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 899px) {
  .FooterLinkTitle:after {
    display: block;
  }

  .headerbox {
    margin-top: 10px;
  }

  .mobilemenu .MuiDrawer-paper {
    justify-content: left;
    position: relative;
    align-items: normal;
    display: block !important;
    padding-top: 151px;
  }

  .mobilemenu ul {
    margin-left: 30px;
  }

  .headerbox.scrolled {
    width: 100% !important;
  }

  .hero-content {
    text-align: center;
    float: none;
  }

  .hero-content h1 {
    font-size: 32px;
    font-weight: 500;
  }

  .hero-content p {
    font-size: 24px;
    line-height: 30px;
    margin-top: 15px;
  }


  .BRLogo img {
    width: 22vh;
  }
 
  .headerbox>div {
    align-items: center;
  }

  html[style="overflow: hidden;"] header.headerbox.scrolledSticky {
    position: fixed !important;
  }

  .toptechnologies {
    padding-left: var(--gap);
    padding-right: var(--gap);
    width: calc(100% - 30px);
    flex-wrap: wrap;
    gap: 0;
  }

  .technologiesItem {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 33%;
    gap: 15px;
    flex-direction: row;
  }

  .custom-arrow {
    top: 0;
    padding: 0;
  }


  .Technologies ul>li>div {
    padding: 24px 0;
  }

  .footerlinks ul {
    flex-wrap: wrap;
    display: block;
  }

  .footerlinks ul li {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .footerlinks b {
    width: 100%;
    display: block;
    border-bottom: solid 1px #cccc;
    padding-bottom: 16px;
    padding-top: 0;
    margin-bottom: 0;

    font-size: 14px;
  }

  .footerlinks ul li.technology b {
    border: 0;
  }

  .FooterBottom {
    flex-direction: column;
  }

  .socialarea {
    margin-top: 16px;
    border-top: 1px solid #666;
    padding-top: 16px;
    justify-content: center;
  }

  .FooterLinkTitle {
    display: block;
  }

  .socialarea {
    margin-top: 0px;
  }

  .socialarea a {
    margin-bottom: 0;
  }

  .joinourteam a {
    margin-top: 8px;
  }

  .mobile.hide-model {
    display: none;
  }

  /*Set footer link Hide in mobile view*/
  .mobile.show-model {
    display: block;
    flex-direction: column;
    padding-top: 15px;
    border-bottom: solid 1px #ccc;
    padding-bottom: 15px;
    margin-top: -40px;
    margin-bottom: 20px;
  }

  /*Set footer link show in mobile view*/

  .footerlinks ul li ul li .mobile.show-model {
    border-bottom: none;
  }

  .footerlinks ul li ul li:last-child .mobile.show-model {
    border-bottom: solid 1px #ccc;
  }


  .FooterLinkTitle::after {
    display: block !important;
  }

  .footerlinks b.active {
    border: none;
    color: var(--brand);
  }

  .joinourteam {
    padding-bottom: 0;

  }

}

@media only screen and (max-width: 767px) {

  .hero-content {
    padding: 30px;
    margin: 20px;
  }

  .hero-content p {
    font-size: 18px;
    line-height: 24px;
  }

  .toptechnologies {
    width: calc(100% - 30px);
  }

  .custom-arrow {
    top: 0px;
  }

  .FooterbottomLeft {
    flex-direction: column;
    justify-content: center;
  }

  .footerlastlinks {
    text-align: center;
  }

  .footerlastlinks span span {
    display: block;
  }
}

@media only screen and (max-width: 677px) {
  .technologiesItem {
    width: 49%;
  }

  .OurServices .MuiGrid-container>div {
    flex-basis: 51%;
    max-width: 50%;
  }

  .technologyImg {
    width: 30px;
  }

  .cd-nav-trigger {
    top: 0;
  }

  .BRLogo img {
    width: 21vh;
  }
}

@media only screen and (max-width: 480px) {
  .companydetails b {
    position: relative;
    width: 100%;
  }

  .companydetails>span,
  .companydetails>a {
    margin-left: 0;
  }

  .technologyName {
    font-size: 14px;
  }

  .Technologies ul>li>div>img {
    height: 24px !important;
  }

  .sharebtngroup {
    flex-wrap: wrap;
    white-space: nowrap;
    max-width: 100%;
    gap: 0px 15px !important;

  }

  .sharebtngroup>div {
    justify-content: flex-end;
    display: flex
  }

  .position_btn span,
  .sharebtn span {
    box-sizing: border-box;
    padding: 0;
  }

  .position_btn span svg,
  .sharebtn span svg {
    width: 16px;
    height: 16px;
  }
}

.processImage {
  width: 100%;
}

/* Start constact us let's talk page */

.contactContainer {
  padding:20px;
}

.contactSection {
  margin-bottom:20px;
}

.custtl {
  display:block;
  font-family:'ubunturegular', sans-serif;
  text-align:left;
  margin-bottom:4px;
  color:#686868;
  font-size: 20px;
}

.errorNotification {
  color:red;
  margin-top:8px;
}

/* ControlPanelHeader Css start */
.visitorsmenu {
  background-color: #fff !important;
  /* Dark background */
  color: #333 !important;
  padding: 0 !important;
  border-bottom: solid 1px #ccc !important;
  margin-bottom: 16px !important;
}

.visitorsmenu .MuiTab-root {
  font-weight: bold !important;
  text-transform: none !important;
  padding: 10px 20px !important;
  color: #333 !important;
  transition: color 0.3s ease, border-bottom 0.3s ease !important;
}

.visitorsmenu .Mui-selected,
.visitorsmenu .MuiTab-root:hover {
  color: #ff5722 !important;
  /* Active tab text color */
}

.visitorsmenu .MuiTabs-indicator {
  background-color: #ff5722 !important;
  /* Active tab underline color */
  height: 2px !important;
  /* Adjust underline thickness */
}

/* ControlPanelHeader Css end */

.css-17bgv52 {
  display: flex !important;
  gap: 16px !important;
  justify-content: left !important;
}


/* css for CaseStudyList page start --------------- */
.carousel-container {
  padding: 1px;
}

/* #Case-Study-List .react-multi-carousel-list {
  position: unset !important;
} */

/* End--------------------- */

/* css for career page share links ------------------ */


.tooltip:hover .tooltip-content {
  display: block;
}

/* End---------------------- */

#work {
  scroll-behavior: smooth;
  scroll-margin-top: 70px;
}

#services {
  scroll-behavior: smooth;
  scroll-margin-top: 120px;
}

#apply {
  scroll-behavior: smooth;
  scroll-margin-top: 120px;
}

#process {
  scroll-behavior: smooth;
  scroll-margin-top: 100px;
}

.readmorebtn,
a.decorationtext {
  color: #666666;
  margin-top: 15px;
  text-align: right;
  text-decoration: none;
}

.readmorebtn a:hover,
.readmorebtn a:focus,
a.decorationtext:hover,
a.decorationtext:focus {
  background-color: rgba(226, 88, 59, 0.7);
  border-color: transparent;
  box-shadow: none;
  color: #fff;
  padding: 2px 4px;
}



.wrapperdropdown:focus-visible {
  outline: none !important;
}

.select-option:hover {
  background-color: #f8f8f8 !important;
  color: #d1422c !important;
}

.testimonial-author {
  width: 100%;
  font-size: 15px !important;
  color: #2f2f2f !important;
  text-align: right !important;
  margin-top: 10px !important;
  font-family: 'ubuntuitalic' !important;
}