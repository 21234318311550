@import url(../../variable.css);
@import url(./animation.css);

/*Page Title*/
.LeftTitle h1 {
  text-align: left;
  padding-right: 10px !important;
  width: 80%;
  padding-left: 38px;
}

.RightTitle h1 {
  text-align: right;
  padding-right: 10px !important;
  width: 80%;
}

/*Carear PAge Style --- START*/

.open_position {
  margin-top: 60px;
}

.open_position h4 {
  font-family: var(--ubunturegular) !important;;
  width: 100%;
  font-size: 30px !important;
  color: #e2593a !important;
  font-weight: normal;
  margin-bottom: 28px;
  padding-left: 0;
  text-align: left !important;
}

/*Career *page Style --- END*/
.Title2 {
  width: 100% !important;
  font-size: var(--orangetitle) !important;
  color: var(--brand) !important;
  margin-bottom: 28px !important;
  text-align: left !important;
  padding-left: 0px;
}

.career-detail-page h5 {

  font-family: var(--ubunturegular) !important;;
  width: 100%;
  font-size: 30px;
  color: #e2593a;
  font-weight: normal;
  margin-bottom: 28px;
  padding-left: 22px;
}

.position_list {

  background-color: #ececec;
  padding: 28px 22px;
  box-sizing: border-box;
  margin-bottom: 10px;
}

.position_list_title {
  margin-bottom: 15px !important;
  font-size: 20px !important;
  color: #313131 !important;
  text-align: left;
}

.position_dsc {
  width: 100%;
  margin-top: 16px;
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

.position_dsc p {
  display: flex;
  width: 50%;
}

.position_dsc p b {
  width: 150px;
  font-size: 17px;
  display: block;
  line-height: 29px;
  color: #444444;
  font-family: 'ubunturegular';
  font-weight: normal !important;

}

.position_dsc h3 {
  margin-bottom: 15px;
  font-size: 20px;
  color: #313131 !important;
  font-family: var(--ubunturegular) !important;;
  font-weight: normal;
  margin: 50px 0 30px 0;
}

.position_dsc p {
  font-size: 17px;
  line-height: 29px;
  color: #444444;
  font-family: 'ubunturegular';
}

.expan_list,
.apply_form_container {
  margin-top: 16px;
  padding: 28px 22px !important;
  border-radius: 16px;
  border: solid 5px #ececec;
  margin-bottom: 30px;
}

.apply_form_container h3 {
  margin-bottom: 15px;
  font-size: 20px !important;
  color: #313131 !important;
  font-family: var(--ubunturegular) !important;;
  font-weight: normal;
}
.loginform{
  margin: auto;
  width: 320px;
}
.apply_form_container .submitbtn , .progress-button{
  margin-top: 30px;
  line-height: 2.7;
  padding: 0px 0px;
  text-transform: inherit;
  font-family: var(--ubunturegular) !important;;
  font-weight: normal;
  font-size: 20px;
  text-transform: uppercase;
  text-align: center;
  background: #e2593a !important;
  min-width: 200px;
}

.posittion_details {
  font-size: 17px !important;
  line-height: 29px !important;
  color: #444444 !important;
  font-family: 'ubunturegular' !important;
  text-align: left;
}

.jd h3 {
  font-size: 18px;
  color: #313131;
  font-family: 'ubunturegular';
  font-weight: normal;
  margin-top: 0;
}

.jd ul {
  margin: 14px 0 30px 0;
  padding: 0;
}

.jd ul li {
  /* background: url(/images/list_arrow.png) no-repeat scroll 0% 12px; */
  font-size: 15px;
  color: #6e6e6e;
  line-height: 20px;
  list-style: none;
  padding-left: 24px;
  margin-bottom: 15px;
}

.position_righ_box p {
  margin: 20px 0 0 0;
  max-width: 100%;
  font-size: 15px !important;
  line-height: 25px !important;
  color: #656565;
  font-family: 'ubunturegular' !important;
  text-align: left;
  /* background-color: #f6f6f6; */
}
.position_btn,
.sharebtn {
  color: #000000 !important;
  border: none;
  outline: none;
  height: 36px;
  padding-right: 0;
  margin-right: 0;
  cursor: pointer;
  font-size: 18px;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.position_btn span,
.sharebtn span {
  width: 30px !important;
  height: 30px !important;
  font-size: 16px !important;
  border: solid 1px var(--brand);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  border-radius: 100%;
  padding: 4px;
  color: var(--brand);
  background-color: #fff;
}

.position_btn:hover,
.sharebtn:hover {
  color: var(--brand) !important;
}

.sendbtn {
  line-height: 2.7 !important;
  padding: 0px 70px !important;
  text-transform: inherit !important;
  font-family: var(--ubunturegular) !important;;
  font-weight: normal !important;
  font-size: 20px !important;
  text-transform: uppercase !important;
  text-align: center !important;
}

/*Inner page Main heading*/
 .open_position h1, .Title2, .secondheadingh5,ul.service-list li ,.pb_customtab button, .position_list_title, .position_btn,
 .sharebtn {
  font-family: var(--ubuntulight) !important;
  font-weight: normal !important;
}
/*Mian heading style*/
.techno_content h2,  .contentpage h2, .prjover-main-title,
.contentpage h2.prjover-main-title, .csrow2_title, h5.csrow2_title{
  font-size: var(--heading) !important;
  font-family: var(--ubuntulight) !important;
  font-weight: normal !important;
}

/*content style page intro*/
.contentpage p,ul.service-list li, .pb_customtab button, .page_intro p, .csc-content, .csc-content p{
 
  font-family: var(--ubuntulight) !important;
  font-weight: normal !important;
 font-size: 21px;
 line-height: 34px;
}
/**/
.page_intro p, .contentpage p, .csc-content, .csc-content p{
  color: #656565;
}
/*Sub Heading style*/
.secondheadingh5{
  font-size: 28px !important;
  font-family: var(--ubuntulight) !important;
}
.contentpage h2, .prjover-main-title,
.contentpage h2.prjover-main-title, .csrow2_title, h5.csrow2_title  { 
  text-align: center;
  width: 100%;
  display: block;
  margin-bottom: 25px;
  color: #656565;
}
.contentpage h6 {
  width: 100%;
  color: #e2593a !important;
  margin-bottom: 15px !important;
  font-family: var(--ubunturegular) !important;;
  font-size: 24px !important;

}

.contentpage p {
  margin-bottom: 20px;
}

.content-address-header{
  font-size: 18px !important;
  font-weight: bold !important;
}

.contentpage p.MuiTypography-body2 {
  color: #666666;
  font-family: var(--ubuntulight) !important;;
  font-size: 16px;
  line-height: 26px;
  text-align: left;
}

/* Common Form UI change --- START */
.formstyles.MuiInputBase-formControl {
  margin: 0 !important;
}

.apply_form>div,
.BrowseFile {
  width: calc(49% - 1px);
  text-align: left;
  margin: 20px 0 !important;
}

.BrowseFile {
  position: relative;
  border-bottom:solid 1px  rgba(0, 0, 0, 0.42);
}

.BrowseFile div::before{
  display: none !important;
}
.btnbrowse {
  background-color: #d1d1d1 !important;
  padding: 14px 0 !important;
  color: #333333 !important;
  text-decoration: none;
  transition: background .15s cubic-bezier(.33, .66, .66, 1) !important;
  -o-transition: .5s;
  -ms-transition: .5s;
  -moz-transition: .5s;
  -webkit-transition: .5s;
  border: none !important;
  border-radius: 0 !important;
  width: 130px;
  height: 42px;
  margin-top: 8px !important;
  text-transform: none !important;
  box-shadow: none !important;
  position: absolute !important;
  right: 0;
  bottom: 1px;
}

.btnbrowse:hover {
  background-color: #000;
  color: #fff;
}
.BrowseFile p {
  padding-right: 130px;
  line-height: 18px;word-wrap: break-word;        margin-top: 20px;
}
.BrowseFile input {
  margin-top: -17px !important;
  padding: 15px !important;
  border-bottom:solid 1px  rgba(0, 0, 0, 0.42);
  font-size: 16px !important;
  font-weight: normal;
  font-family: 'ubunturegular';
  color: #868685 !important;
}

.formstyles p.Mui-error,.formstyles p.Mui-warn {
  font-family: 'ubunturegular';
  background: #fafafa;
  border: 1px solid #e1e1e1;
  padding: 5px 15px;
  /* min-width: 150px; */
  font-size: 14px;
  color: #595858;
  -webkit-box-shadow: 0px -6px 10px 0px rgba(197, 197, 197, .6);
  -moz-box-shadow: 0px -6px 10px 0px rgba(197, 197, 197, .6);
  box-shadow: 0px -6px 10px 0px rgba(197, 197, 197, .6);

  position: absolute;
  right: 0;
  z-index: 2;
  bottom: -53px;
  display: flex;
  justify-content: left;
  flex-direction: row-reverse;
  align-items: center;
  gap: 10px;
  margin: 0;
}

.formstyles p.Mui-error::before, .formstyles p.Mui-warn::before {
  width: 0;
  height: 0;
  border-left: 10px solid transparent !important;
  border-right: 10px solid transparent !important;
  border-bottom: 10px solid #fff !important;
  position: relative;
  content: "";
  position: absolute;
  top: -8px;
}

.formstyles p.Mui-error::after ,.formstyles p.Mui-warn::after {
  background: url('../../assets/Images/common/Error-Icon.jpg');
  width: 20px;
  height: 20px;
  content: "";

}
.formstyles .BrowseFile  p.Mui-warn::after  {
  background: url('../../assets/Images/common/warning.svg');}
.MuiInput-underline::after,
.Mui-error::before, .Mui-warn::before {
  border-width: 5px !important;
}

.formInput {
  margin: 20px 0 !important;
}

.modalQBtn {
  margin-top: 20px !important;
}

.apply_form label,
input[type=file] {
  color: #868685 !important;
  font-size: 18px;
  font-weight: normal;
  font-family: var(--ubunturegular) !important;;
}
.apply_form.formstyles {
  gap: 5px;
}
.apply_form p.Mui-error,
.modalQuotoMain p.Mui-error,.apply_form p.Mui-warn {
  bottom: -45px !important;
}


.formstyles input[type=file] {

  padding-right: 137px !important;
  margin-top: 2px;
  padding-bottom: 8px;

}


.formstyles input[type=file]::file-selector-button {
  background-color: #d1d1d1;
  color: #000;
  border: 0px;
  border-right: 1px solid #e5e5e5;
  font-family: 'ubunturegular';
  font-size: 14px;
  padding: 15px 15px;
  margin-right: 0px;
  transition: .5s;
  position: absolute;
  right: 0;
  margin-top: -8px !important;
}


.formstyles input[type=file]::file-selector-button:hover {
  background-color: #000;
  border: 0px;
  border-right: 1px solid #e5e5e5;
  color: #ffff;
}

/* Common Form UI change --- END */
.cs_contact_container {
  /* max-width: 810px; */
  margin: 0 auto;
  width: 90%;
}

.csc-content,
.csc-content p {
  max-width: 990px; 
  text-align: center;
  margin: 25px auto;
}

.tooltip {
  display: inline;
  position: relative;
  z-index: 98;
}

.tooltip::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 20px;
  bottom: 100%;
  left: 50%;
  pointer-events: none;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.tooltip:hover::after {
  pointer-events: auto;
}

.tooltip-content {
  /* display: none; */
  position: absolute;
  z-index: 9999;
  width: 160px;
  left: 0%;
  bottom: 98%;
  text-align: center;
  background: transparent;
  /* opacity: 0; */
  margin: 0 0 20px -100px;
  cursor: default;
  /* -webkit-transition: opacity 0.3s 0.3s;
  transition: opacity 0.3s 0.3s; */
  transition: all 0.5s ease;  
}

.tooltip:hover .tooltip-content {
  opacity: 1;
  pointer-events: auto;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.tooltip-content div {
  display: block;
}

.tooltip-text {
  border-bottom: 2px solid #d5452c;
  overflow: hidden;
  -webkit-transform: scale3d(0, 1, 1);
  transform: scale3d(0, 1, 1);
  -webkit-transition: -webkit-transform 0.3s 0.3s;
  transition: transform 0.3s 0.3s;
}

.tooltip:hover .tooltip-content {
  display: block;
}

.tooltip:hover .tooltip-text {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
}

.tooltip-inner {
  border-bottom: var(--brand) solid;
  background: rgba(85, 61, 61, 0.95);
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
  webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  background: #fff;
  width: 100%;
  padding: 4px 5px 0;
}

.tooltip:hover .tooltip-inner {
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.tooltip-content::after {
  content: '';
  bottom: -12px;
  right: 45px;
  border: solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: transparent;
  border-top-color: #d5452c;
  border-width: 6px;
  margin-left: 0;
}

.sharebtngroup {
  display: flex;
  justify-content: flex-start !important;
  gap: 20px;
}

.carousel-item {
  cursor: default !important;
}

.position_btn span svg,
.sharebtn svg {
  width: 20px !important;
}

.shareicons {
  width: 100%;
}

.shareicons li {
  display: inline-block;
  width: 30px;
  text-align: center;
}

.shareicons li img {
  width: 25px;
  height: 25px;
}

.alltraining {
  margin: 15px 0;
  display: block;
  background: var(--brand);
  padding: 15px;
  border-radius: 2px;
  color: #fff;
  text-decoration: none;
  border: solid 1px var(--brand);
}


.alltraining:hover {
  background-color: #fff;
  color: var(--brand);
  background-color: #fff;
  color: var(--brand);
}

.conuntryName {
  text-align: left;

  width: 100%;
  font-size: 30px;
  color: #e2593a !important;
 font-family: var(--ubunturegular) !important;;
  font-weight: normal;

}

.life_br {
  width: 100%;
  background-color: #f6f6f6;
  padding: 30px 28px;
  margin: 30px 0;
}

.address {
  font-size: 16px !important;
  color: #686868;
  line-height: 28px !important;
  font-family: var(--ubunturegular) !important;
  font-style: normal;
  font-weight: normal !important;
  margin-top: 25px !important;
  text-align: left;
}

.address ul {
  display: flex;
}

.address ul li,
.address ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.address ul li span {
  display: block;
  width: 60px;
}

.address p b {
  width: 100% !important;
  font-size: 15px !important;
  color: #2f2f2f !important;
  text-align: right !important;
  margin-top: 20px !important;
}

.address a {
  color: #686868;
  cursor: pointer;
  text-decoration: none;
}

.address a:hover {
  color: var(--brand);
}
/*Service PAge*/
.servicesLogo ul {
  display: inline-flex;
  gap: 40px;
  list-style: none;margin: 0;padding: 0;
}
.servicesLogo span img {
  filter: grayscale(100%);
  height: 45px;
}
.servicesLogo span:hover img {
  filter: grayscale(0);
}
/*Our work page - styles*/
/* .workcard{} */
a.sharepointServiceancheor {
  text-decoration: none;
  color: #666;
}

.underlineancorwhitebg {
  border-bottom: 3px solid rgb(0 0 0 / 40%);
  box-shadow: inset 0 -2px 0 rgb(0 0 0 / 40%);
}

.page_intro {
  margin-bottom: 10px !important;
  text-align: center;
  margin-top: 25px !important;
}

.ourworkiteams {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 60px;
  margin-top: 60px;
}

.casestudies {
  max-width: 100% !important;
  box-shadow: none !important;
}
.stack-hovered .workcard:first-child , .workcard:hover, .stack-hovered .workcard:first-child .casestudies,  .workcard:hover .casestudies{
  background-color: #d1422c;
  color: white;

}
.workcard:hover:after {
  width: 99%;
}
.stack-hovered .workcard:first-child .casestudies button {
  background-color: #fff !important;
  visibility: visible;

}
.workcard {
  margin: 0 !important;
  width: 20%;
  padding:0 15px;
  border-radius: 4px;
  margin-top: -1px !important;
}

.casestudiesIteam {
  height: 450px;
  border-bottom: solid 1px var(--brand);
}

.casestudiesIteam img {
 margin: 0 !important;
}

.showcase-btn {
  display: inline-block !important;
  visibility: hidden;
  text-align: center !important;
  padding: 11px 20px !important;
  border-radius: 2px !important;
  color: rgb(213, 69, 43) !important;
  /* margin-left: 10px; */
  margin: 20px auto 20px !important;
  background: #fff !important;
  border: 1px solid #d5452b !important;
  font-family: var(--ubunturegular) !important;;
  font-weight: normal !important;
  font-size: 16px !important;
  text-transform: uppercase !important;
  letter-spacing: 1px !important;
}

.letstalk-btn {
  width: 190px !important;
  padding: 10px 0 !important;
  font-family: var(--ubuntulight) !important;;
  font-size: 18px !important;
  color: #fff !important;
  background: #e2593a !important;
  border: 1px solid #e2593a !important;
  display: inline-block !important;
  cursor: pointer !important;
  text-transform: none !important;
  letter-spacing: 1px;
}

.letstalk-btn:hover {
  color: #e2593a !important;
  background: #fff !important;
  border: 1px solid #e2593a !important;
}

.casestudies:hover .showcase-btn {
  visibility: visible;
}



.casestudies:hover .showcase-btn:hover {
  border-color: #ffff !important;
  color: #ffff !important;
  background-color: #d5452b !important;
}

.prj_client_quote {
  width: 400px;

  margin-left: 30px;
  margin-top: 30px !important;
  color: #666666 !important;
  font-family: var(--ubunturegular) !important;;
  font-size: 18px !important;
  line-height: 26px !important;
  text-align: left !important;
  text-align-last: left !important;
  margin: 0;
}

.client_quote_w {
  display: flex;
  position: relative;
  padding: 20px 60px;
  background: #E1E1E1;
  color: #666666;
  font-family: var(--ubunturegular) !important;;
  font-size: 18px !important;
  line-height: 26px !important;
  text-align: left;
  text-align-last: left;
  margin: 0;
  font-display: normal !important;
}

.prj_scrnshot {
  width: 400px;

  border: 1px solid #e2e2e2;
  max-height: 400px;
  overflow: hidden;
  cursor: pointer;
}

.client_quote_w span {
  color: #e2593a;
  font-size: 100px;
  font-family: initial;
  font-weight: bold;
}

.client_quote_w span.left {
  width: 49px;
  height: 39px;
  position: absolute;
  left: 5px;
  top: 43px;
}

.client_quote_w span.right {
  position: absolute;
  right: 14px;
  bottom: -8px;
  /* background-position: 0px -37px; */
  width: 51px;
  height: 40px;
}

.quote_rounded {
  border-radius: 10px;
}

.companydetails {

  gap: 10px;
  color: #666666;
  width: 100%;

  font-family: var(--ubuntulight) !important;;
  margin-bottom: 13px;
  font-size: 18px;
}

.companydetails>span,
.companydetails>a {
  margin-left: 150px;
}

.companydetails b {
  color: #333333;
  width: 150px;
  font-family: 'ubunturegular';
  text-transform: uppercase;
  font-weight: normal;
  display: block;
}

.orangseperator {
  font-size: 16px;
  margin-top: 3px;
  color: #e2593a !important;
  font-style: normal;
  font-weight: bold;
  margin-right: 5px;
}

.companydetails a {
  margin-right: 5px;
  /* padding: 2px 5px; */
  font-family: var(--ubunturegular) !important;;
  text-decoration: none;
  color: #e2593a;
  text-decoration: underline;
  text-decoration-color: rgba(226, 89, 58, 0.4);
  text-decoration-thickness: 3px;
  padding: 4px 7px !important;
  margin-bottom: 20px;
  margin-top: 0;
}

.companydetails ul {
  list-style: none;
  display: flex;
  margin: 0 0 15px 0;
  padding: 0;
}
.companydetails ul li:first-child {
  width: 135px;
  flex: 0 1 135px;
}
.companydetails ul li:last-child {
  width: calc(100% - 120px);
}
.companydetails a.active,
.companydetails a:hover {
  background-color: rgba(226, 89, 58, 0.7);
  border-color: transparent;
  box-shadow: none;
  color: #fff;
  text-decoration: none !important;
  padding: 4px 7px !important;
}


/*SharePoint Consulting Style**/
.secondheadingh5 {
  text-align: left !important;
    color: #e2593a !important;

}

.industries_title {
  font-family: var(--ubunturegular) !important;;
  width: 100%;
  font-size: 30px !important;
  color: #000;
  font-weight: normal;
  margin-bottom: 28px !important;
}

ul.service-list {
  width: calc(100% - 30px);
  text-align: left;
  margin-left: 30px;
  text-align: left;
  margin-left: 30px;
}


ul.service-list li {
  margin-bottom: 15px;
  list-style-type: circle;
  color: #091d23;
  margin-left: 30px;
}

ul.service-list li span {
  text-align-last: left;
  font-size: var(--bodycontentfontsizelg) !important;

  font-family: var(--ubuntulight) !important;;
}

.link {
     align-items: center;
    border-bottom: 2px solid;
    color: #fff;
    display: inline-flex;
    margin-left: 8px;
    padding: 0 15px 0 2px;
    position: relative;
    text-decoration: none;
    white-space: nowrap;

}
.link svg {
  position: absolute;
  right: -6px;
}

.salesforceservices {
  padding: 30px !important;
}

.link:hover {
  background: rgb(255 255 255 / 38%);
}

/*AI chatbot*/
.custom.tabs {
  justify-content: center;
}

.cardtitle {
  font-size: 18px !important;
  font-weight: normal !important;
  margin-bottom: 0;
  text-align: center !important;
  margin-top: 10px !important;
  color: #000 !important;
}

.industries_title {
  font-family: var(--ubunturegular) !important;;
  width: 100%;
  font-size: 30px;
  color: #000;
  font-weight: normal;
  margin-bottom: 28px;
}

.chatbotprod {
  max-height: 450px;
}
.chatbotprod h3 {
  min-height: 42px;
}
.chatbotprod:hover h3 {
  color: #fff !important;
}

.chatbotprod ul {
  margin: 15px 0;
}

.chatbotprod ul li {
  margin-left: 0;
  line-height: 30px;
  padding: 0px 0 0 25px;

  position: relative;
}

.chatbotprod ul li span {
  font-size: 14px !important;
}

.chatbotprod ul li:before {
  content: "";
  top: 5px;
  font-size: 18px;
  width: 20px;
  height: 20px;
  position: absolute;
  --svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m9 6l6 6l-6 6'/%3E%3C/svg%3E");
  background-color: var(--brand);
  -webkit-mask-image: var(--svg);
  mask-image: var(--svg);
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
  left: 0;
}

.powerbitab {
  background-color: #000 !important;
  color: #FFFFFF !important;
}

.pb_customtab {

  background: transparent !important;
  color: #000 !important;
  box-shadow: none !important;

}

.BlackbgTab .pb_customtab {

  background: transparent !important;
  color: #fff !important;
  box-shadow: none !important;

}

.BlackbgTab .pb_customtab button {
  color: #fff !important;
  max-width: 25%;min-width: 200px;
  line-height: 18px;
}
.threeTab .BlackbgTab .pb_customtab button{ max-width: 33%;}
.BlackbgTab .pb_customtab button {
  border-bottom: solid 2px #fff !important;
  margin-bottom: 4px;
}
.BlackbgTab .pb_customtab button.Mui-selected{
  border-bottom: solid 5px var(--brand) !important;
  margin-bottom: 3px;

}

.pb_customtab button {
  color: #000 !important;
  text-transform: none;
  font-size: 18px;
}

.pb_customtab .MuiTabs-indicator {
  background: #000;
}

.pb_customtab>div {
  flex-wrap: nowrap;
}
.whitetabcontainer{

}
.whitetabcontainer .pb_customtab{
  border-bottom: solid 1px #ccc;
}
.olderlink {
  text-decoration: none !important;
  color: #666666 !important;
  border-bottom: none !important;
  box-shadow: none !important;
  background: transparent !important;
  text-transform: none !important;
}

.olderlink:hover {
  background-color: var(--brand) !important;
  color: #fff !important
}

.event-accordion {
  width: 300px;
  margin-left: 30px;
  background: #fff;
  padding: 20px;
}

.event-accordion h6 {
  border-bottom: 1px solid #d3d2d2;
  padding-bottom: 15px;
  font-weight: normal;
  width: 100%;
  font-size: 16px;
  color: #838383;
  font-family: 'ubunturegular';
}

.trainingbox {
  box-shadow: none !important;
}

/* .event-accordion ul li{

} */
/*Sharepoint Training*/
.event-accordion ul li {
 font-family: var(--ubunturegular) !important;;
  position: relative;
  padding: 10px 12px 0px 0px;
  margin-bottom: 0;
  display: block;
  cursor: pointer;
  transition: all 300ms ease-out;
  color: #000;
  text-transform: uppercase;
  font-weight: normal !important;
}

.event-accordion ul li:hover {
  color: var(--brand) !important;
}

.event-accordion ul li ul li {
  padding: 10px 0 10px 20px;
  color: #5588aa;

}

.event_date {
  border-bottom: 1px solid #dcdcdc;
  padding-bottom: 20px;
  font-size: 14px !important;
  color: #838383 !important;
  font-weight: normal !important;
  text-transform: uppercase;
}

.event_title {
  font-size: 28px !important;
  text-transform: uppercase;
  color: #646464;
  font-family: var(--ubunturegular) !important;;
  font-weight: normal !important;
  margin: 20px 0 !important;
}

.event_content {
  margin: 20px 0px !important;
  width: 100%;
  font-size: 15px !important;
  line-height: 25px !important;
  color: #858585 !important;
  font-family: 'ubunturegular' !important;

}

.spe-page-list a {
  font-size: 16px;
  font-family: "ubunturegular";
  text-decoration: none;
  color: #666666 !important;
}

.spe-page-list p {
  margin: 0;
}

.spe-page-list a.active,
.spe-page-list a:hover {
  color: var(--brand) !important
}

.spe_date_grop {
  display: inline-flex;
  margin: 20px 0;
  justify-content: flex-start;
}

.spe_loction {
  display: inline-flex;
  float: right;
}

.spe_loction p {
  text-align: right;
  color: #fff;
  padding-left: 15px;
  font-size: 18px;
  font-family: var(--ubunturegular) !important;;
  font-weight: 300;
  display: flex;
  justify-content: end;
  margin-top: 30px;
}

.spedate {
  font-size: 64px !important;
  float: left;
  line-height: 43px !important;
  margin-right: 3px !important;
}

.eventDescription {
  display: -webkit-box;
  overflow: hidden;
  font-size: 16px !important;
  width: 100%;
  height: 70px;
}

.spe_monthyear p {
  font-size: 22px !important;
  margin: 0 !important;
  line-height: 26px !important;

}
.speyear {
  font-family: 'ubuntulight';
}
.eventTitle {
  color: #fffefe;
  font-family: ubunturegular !important;
  font-size: 20px !important;
  margin-bottom: 15px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 15px !important;
}

.morelink {
  color: white;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.morelink svg {
  width: 18px;
}
.underlineancorwhitebg{
  border-bottom: 3px solid rgb(0 0 0 / 40%);
  box-shadow: inset 0 -2px 0 rgb(0 0 0 / 40%);
}
.spl_title {
  font-size: 20px;
  font-family: "ubunturegular";
  color: #fffefe;
  margin-bottom: 15px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

}

.pppage h4 {
  font-size: 28px !important;
  color: #e2593a !important;
  font-family: var(--ubuntulight) !important;;
  font-weight: normal !important;
  width: 100%;
  display: block;
  text-align: left;
  margin-bottom: 25px !important;

}
.pppage li, 
.pppage ul {font-family: var(--ubuntulight) !important;
  font-size: 21px !important;
  color: #656565;}

  .pppage li{
  
  margin-bottom: 15px !important;
  list-style-type: circle;
  margin-left: 50px !important;
  text-align-last: left;

}

.pp_btmline {
  width: 100%;
  font-size: 23px !important;
  color: #444 !important;
  line-height: 30px !important;
  font-family: 'ubunturegular';
}


/*CaseStudy Template*/
.compnay_detail_div {
  margin-top: 50px;
  margin-bottom: 30px;
}

/* .workbtn{

} */
.cmp_dtl_title {
  font-size: 28px !important;
  font-family: 'ubunturegular' !important;
  color: #e2593a !important;
  margin-top: 24px !important;
  margin-bottom: 24px !important;
}



.company_logo {
  width: 360px;
  align-items: center;
  display: flex;
  text-align: center;
  height: 300px;
  background: #fff;
  -webkit-box-shadow: 0px 0px 12px 3px rgba(128, 128, 128, 0.61);
  -moz-box-shadow: 0px 0px 12px 3px rgba(128, 128, 128, 0.61);
  box-shadow: 0px 0px 12px 3px rgba(128, 128, 128, 0.61);
  margin-right: 30px;
  margin: 0 24px 0px 0 !important;
  position: relative;
  /* top: 50px */
}

.company_logo img {
  height: auto !important;
}

.custselcontrol {
  margin-left: 10px;
  margin-right: 20px;
}

.resetFilterBtn {
  background-image: url(../../assets/Images/common/refresh.png);
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-position: 3px 6px;
  padding: 0 !important;
  margin-top: 15px !important;
}

.resetFilterBtn svg {
  display: none;
}

.custselcontrol .MuiSelect-standard {

  width: 190px !important;
  font-size: 14px;
  color: #5d5d5d;
  font-family: 'ubunturegular';
  font-weight: normal;
  padding: 12px 20px 12px 15px;
  text-align: left;
}

.custoption {
  display: block !important;
  text-decoration: none !important;
  color: #333 !important;
  padding: 5px 10px !important;
  transition: all 0.3s ease-out !important;
  border-bottom: 1px solid #e6e8ea !important;
  font-size: 14px !important;
  color: #5d5d5d !important;
  font-family: 'ubunturegular' !important;
  font-weight: normal !important;
  cursor: pointer !important;
  margin: 5px !important;
  background: transparent !important;
}

.custoption:hover {
  color: var(--brand) !important;
}

ul[role="listbox"] li:first-child {
  margin-top: 0 !important;
}

ul[role="listbox"] li:last-child {
  margin-bottom: 0 !important;
  border-bottom: 0;
}

.custselcontrol::before {
  border-bottom: 2px solid #aeaeae !important;
}

.custselcontrol::after {
  border: none !important;
}

.custselcontrol .MuiSelect-icon {
  background-image: url(../../assets/Images/common/Select-Black-Arrow.png) !important;
  background-color: #fff !important;
  background-repeat: no-repeat;
  fill: none !important;
  background-position: 0px 11px;
  transform: none;
}

.custselcontrol.Mui-focused {
  border-color: #00abff;
}

.prj_overview {
  margin-bottom: 20px;
}

.prjover-title,
.contentpage h6.prjover-title {
  color: #e2593a;
  margin-bottom: 15px !important;
  font-family: 'ubunturegular' !important;
  font-size: 24px !important;
}

.prjover-content ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.prjover-content,
.contentpage p.prjover-content p {

  color: #666666;
  font-family: var(--ubuntulight) !important;;
  font-size: 16px !important;
  line-height: 26px;
  text-align: left;
  text-align-last: left;
  margin-bottom: 10px !important;
  margin-top: 0 !important;
  padding-left: 0 !important;
}

.filterWrapper {
  display: flex;
  flex-direction: row !important;
  justify-content: flex-end;
}

.filterInput {
  display: flex;
  flex-direction: row !important;
  align-items: center;
}

.filterInput label {
  font-size: 15px;
  color: #5d5d5d;

}

.wrapperdropdown {
  margin-left: 10px;
  margin-right: 20px;
  width: 244px !important;
  border-bottom: 2px solid #aeaeae !important;
  font-size: 14px;
  color: #5d5d5d;
  font-family: 'ubunturegular';
  font-weight: normal;
  padding: 12px 15px;
}

.wrapperdropdown option:hover {
  background-color: red;
}

.formstyles fieldset {
  border: 0;
  border-bottom: 2px solid #ddd !important;
  border-radius: 0;
}
.formstyles .Mui-focused:after{
  border-color: var(--brand) !important;
}
.filterInput select:focus {
  border-bottom: 2px solid #00abff !important;
}

.MuiSelect-icon {
  background-color: red;
}

.formstyles .Mui-focused fieldset {
  border-color: #d4503a !important;
  border-width: 4px !important;
}

.workcard>div,
.workcard>div>div,
.workcard>div>div>div {
  /* height: 100%; */
}
.workcard > div, .workcard > div > div, .workcard > div > div >div {
  height: 100%;
}
.workcard > div > div >div > div.casestudiesIteam {
  height: calc(100% - 100px);
}
.casestudiesIteam > div{
  max-height: 125px;
  overflow: hidden;
  /* display: block; */
  background: #fff;
  align-items: center;
  display: flex;
  margin-bottom: 25px 
}
.workcard::after {
  content: "";
  width: 80%;
  border-bottom: solid 1px var(--brand);
  position: relative;
  display: block;
  height: 1px;
  margin: 0 auto;
  margin-top: -2px;
}
.name,
.workcard p {
  font-family: "ubunturegular";
  font-size: 15px;
  font-weight: normal;
  line-height: 19px;

}

.casestudies {
  position: relative;
  background-color: transparent !important;
}

.casestudiesIteam {
  /* height: 100%; */
  padding-bottom: 100px !important;
}

.workbtn {
  width: 100%;
  position: absolute;
  text-align: center;
  bottom: 0;
}

.workcard:hover::after, .workcard:first-child::after{
  background-color: transparent;
}
.ourworkiteams>.workcard .casestudies button {
  width: 190px;
  padding: 11px 10px !important;
  margin-bottom: 0 !important;
}

.workcard h3 {
  font-family: var(--ubunturegular) !important;;
  font-size: 24px;
  font-weight: normal;
  margin: 0.6em 0 0.6em 0;
  line-height: 24px;
}

.modalQBtn {
  position: relative !important;
  display: inline-block !important;
  padding: 0 !important;
  cursor: pointer !important;
  outline: none !important;
  border: none !important;
  background: #e2593a !important;
  color: #fff !important;
  letter-spacing: 1px !important;
  line-height: 2.7 !important;
  border-radius: 2px !important;
  font-family: var(--ubunturegular) !important;;
  font-weight: normal !important;
  font-size: 20px !important;
  text-transform: uppercase !important;
  text-align: center !important;
  width: 100% !important;
}
.modalQBtn img{width: 30px;    margin-bottom: -6px;}
.contactform {
  background: #fff;
  width: 320px !important;
  float: none;
  position: absolute !important;
  box-shadow: 0px 0px 43px 0px rgba(0, 0, 0, 0.47) !important;
  border: 1px solid #ececec !important;
  padding: 20px !important;

}

.closebtn {
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
  cursor: pointer !important;
  background: #dbdbdb !important;
  min-width: auto !important;
  padding: 9px 9px !important;
  border-radius: 0 !important;
}

.formstyles input {
  padding-left: 0 !important;
  padding: 0;
  height: 34px;
  margin-top: 15px;
}

.formstyles textarea {
  margin-top: 10px;
}

.formstyles .MuiInputBase-formControl {
  margin-top: 0 !important;
}

.formstyles label {
  transform: translate(0px, 1px) scale(1) !important;
  color: #868685 !important;
  font-size: 18px !important;
  font-weight: normal;
 font-family: var(--ubunturegular) !important;;
}

.formstyles label.MuiInputLabel-shrink {
  transform: translate(0px, -8px) scale(1) !important;
  font-size: 18px;
}

.formstyles label.Mui-focused {
  transform: translate(0px, -26px) scale(1) !important;
  font-size: 18px !important;
}

.case-study-dialogOverflowNone>div>div {
  overflow: visible;
  width: auto !important;
  max-width: calc(100% - 64px) !important;
}

/*Salesforce Style*/
.imagesarea {
  max-width: 300px;
}

.techno_icon {
  min-height: 300px;
}
.techno_icon_bg{
}
.techno_icon {
  display: flex;
  align-items: flex-start;
  width:360px;
  margin-top: 15px;
  position: relative;
  justify-content: center;
}
.techno_icon img{max-width: 80%;}


.sharepoint_logo1 {
  position: absolute;
  left: 0;
  top: -8px;
}

.servicestavimage{
  display: flex;
      justify-content: center;
      align-items: center;
}
.servicestavimage{
  max-width: 120px;
  width: 100%;
}
.sharepoint_logo2 {
  position: absolute;
  right: 0;
  top: 46px;
}

.sharepoint_logo3 {
  position: absolute;
  left: 0;
  top: 97px;
}

.sharepoint_logo4 {
  position: absolute;
  left: 50px;
  top: 170px;
}

.sharepoint_logo5 {
  position: absolute;
  right: 0;
  top: 110px;
}

.app1 {
  position: absolute;
  left: 0
}

.app2 {
  position: absolute;
  right: 0;
  top: 80px;
}
.app3 {
  position: absolute;
  left: 131px;
  top: 127px;
}

.app4 {
  position: absolute;
  left: 4px;
  top: 84px;

}

.app5 {
  position: absolute;
  left: 148px;
  top: 224px
}

.app6 {
  position: absolute;
  top: 48px;
  left: 100px;
}

.techno_content {
  position: relative;
}
.techno_content h2 br {
  display: none;
}
.techno_content h2 {
  text-align: left;
  color: #fff;
}

.techno_content p {
  text-align: left;
  font-family: var(--ubuntulight);
  font-size: 18px;
  line-height: 28px;
  margin: 15px 0 0;
  color: #fff;
  font-weight: 400;
}

.techno_content a {
  text-decoration: none;
  color: #fff;
  border-bottom: 3px solid rgba(255, 255, 255, .4);
  box-shadow: inset 0 -2px 0 rgba(255, 255, 255, .4);
  /* -webkit-transition: background .15s cubic-bezier(.33, .66, .66, 1); */
  transition: background .15s cubic-bezier(.33, .66, .66, 1);
  transition: all 0.5s ease;
  /* -ms-transition: .5s;
  -moz-transition: .5s;
  -webkit-transition: .5s; */
  text-align: left;
  font-family: ubunturegular;
  font-size: 18px;
  line-height: 28px;
  margin: 15px 0 0;
  color: #fff;
  font-weight: 400;
  cursor: pointer;
}

.techno_content a:active,
.techno_content a:focus,
.techno_content a:hover {
  background-color: #fff;
  border-color: transparent;
  box-shadow: none;
  color: #333;
}
.pagenotfound{
  height: calc(100vh - 278px);
  margin-top:64px;
  display: flex;
  align-items: center;
  color: #fff;
  justify-content: center;
  flex-wrap: wrap;
}
.pagenotfound h4{
  font-size: 3vw;
  font-weight: 500;
  width: 100%;
}
.pagenotfound p{
  font-size: 2vw;
  width: 100%;
  margin-bottom: 50px;
}
.pagenotfound a{
  margin: 3vh;
    margin-bottom: 30px;
    padding: 1.3vh 5vh;
    text-transform: none;
    font-size: var(--bodycontentfontsizelg);
    background-color: var(--brand);
    color: var(--white);
    border: solid 1px transparent;
    font-family: var(--ubunturegular);
    font-weight: normal;
    font-weight: normal;
    border: solid 2px #fff;
    border-radius: 25px;
    /* margin-top: 50px; */
    text-decoration: none;
}
html.hide-scrollbar {
  margin-right: 17px;
  overflow: hidden;
}
.pagenotfound a:hover{
  background-color:rgba(255, 255, 255, .4);

}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
  -webkit-box-shadow: 0 0 0 30px #fff inset !important;
  /* -webkit-text-fill-color: #ffffff !important; */
}

/* Tawk.to whatsapp icon css start */

.wupicomobile, .wupicoweb {
  position: fixed;
     right: 10px;
     bottom: 10px;
     padding:  0px;
     border-radius: 11px;
     width: 50px;
     height: 50px;
     text-align: center;
     background-image: linear-gradient(to top, #2ebc2b, #5df877);
 }
 .wupicoweb img{margin-top: 5px;}
 .wupicomobile{display:none;}

/* Tawk.to whatsapp icon css end */


.imagedialog{
  margin: 0 auto;
    max-width: 1224px;
}
.serviceimage {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
}
.serviceimage img {
  margin: 0 auto;
  /* max-width: 250px;
width: 90%; */
}
.thank_msg h1{
  font-size: 35px;
    font-family: 'ubuntulight';
    font-weight: normal;
    text-align: center;
    width: 100%;
    display: block;
    margin-bottom: 25px;
    color: #656565;
}

/* Hide navigation arrows */
.react-multi-carousel-arrow {
  display: none !important;
}

#Case-Study-List{
  padding: 0;
}
.blackbgcolor  h5.csrow2_title {color:#fff !important}

.case-study-item {
  padding: 0 !important;
  margin: 12px;
  background: #fff;
  transform: transform 0.3s ease;
  border-radius: 16px;
  overflow: hidden;
}
.case-study-item:hover {    transform: scale(1.05);}
.case-study-item p{
height: auto;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  padding: 7px;
  display: table;
  text-align: center;
  width: calc(100% - 14px);
  font-family: var(--ubunturegular) !important;;
  font-size: 16px !important;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;

}
.cstitle{
  text-align: center;
  font-size: 24px;
  color: #333333;
  font-family: var(--ubunturegular) !important;;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 40px;
}
/* Ensure section background is white */
.carousel-container {
}

/* Style for each carousel item */
.carousel-item {
     background-color: transparent;
  border: none;
}

/* Style for images */
.carousel-image {
  width: auto; /* Adjust size to fit 5 items */
  /* height: 24px; */
  object-fit: contain;
  background-color: transparent;
  border: none;
}

/* Margin for text */
.carousel-text {
  margin-top: 8px;
}

/* CSS for HeaderSection */
.landing_content {
margin-top: 101px;  
} 
.heading_title{
color: #ffffff !important;
  font-family: var(--ubuntumedium) !important;
  font-size: 44px !important;
  font-weight: normal !important;
  line-height: 18px !important;
  padding-bottom: 5px !important;
  padding-right: 40px;
  padding-top: 80px !important;
  text-align: right;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  width: 100%;
  box-sizing: border-box;
}

/* CSS for ProductList */

.CardMedia_Image{
width:14% !important;
}

.Card_Button{
margin-left: auto !important;
}
/*Services - Image flashing issue

@keyframes controlledFlash {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}*

.flashing-image {
  animation: controlledFlash 1s ease-in-out infinite; 
  /* Adjust timing for less frequent flashing *
}*/
@media screen and (max-width:1366px) {
  .workcard h3 {
    font-size: 20px;
  }
  .techno_icon img {
    max-width: 300px;
}
.techno_icon a img {
  height: 51px;
}
.techno_icon{
  width: 290px;
}
.techno_icon.inte_thumb {
  width: 100%;
}
.app3 {
  position: absolute;
  left: 103px;
  top: 127px;
}
.app6 {
  position: absolute;
  top: 48px;
  left: 79px;}
  .contentpage p, .techno_content p, .techno_content a{
    font-size: 18px;
    line-height: 26px;
  }
  .contentpage h2{
    font-size: 35px !important;
    margin-bottom: 24px;
  }
  .Title2{    
    font-size: 22px !important;    
    margin-bottom: 20px !important;
  }
  .position_list_title{
    font-size: 18px !important;
  }
  .posittion_details {
    font-size: 14px !important;
    line-height: 26px !important;}
    .position_btn, .sharebtn{
      font-size: 14px;
    }
    .position_btn span, .sharebtn span {
      width: 20px !important;
      height: 20px !important;}
      .address{
        font-size: 14px !important;
      }
      .csc-content, .csc-content p{
        font-size: 18px;    line-height: 24px;

      }
}
  
@media screen and (max-width:1360px) {
.workcard {
  width: 25%;
}
.pb_customtab button{font-size: 16px;}}
@media screen and (max-width:1300px) {
  
  .serviceimage img{
    width: auto;
    max-width: 100%;
  }
}
@media screen and (max-width:1280px) { 
  .cardtitle{
  margin: 0 auto !important;
}
}
@media screen and (max-width:1220px) {
  .content-responsive {
    padding-right: 0px;
}}
@media screen and (max-width:1200px) {
  .companydetails{font-size: 16px;}
  .cmp_dtl_title {
    font-size: 26px !important;
  }
  .ourworkiteams>.workcard {
    flex: 1 1 calc(26.6% - 16px);
    max-width: calc(26.6% - 16px);
    width: calc(26.6% - 16px);
  }

  .compnay_detail_div {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .contentpage h2{
    font-size: 28px !important;
    margin-bottom: 24px;
  }
  .companydetails {
    width: 100%;
  }

  .event-accordion {
    width: 250px;
    margin-left: 15px;
  }
 
}
@media screen and (max-width:899px) {
  .prj_scrnshot{margin-bottom: 30px;}
  .threeTab .BlackbgTab .pb_customtab button{ max-width: 50%;}
  .BlackbgTab .pb_customtab button{ max-width: 50%;}
}
@media screen and (max-width:920px) {
  .techno_icon{
    min-height: 220px;
  }
  .cardtitle{
    width: 100%;
    margin: 0 auto !important;
    text-align: left;
  }
  .ourworkiteams>div {
    width: calc(32.5% - 20px);
  }
  .footerlinks ul li {
    margin-right: 6%;
}

  .heading_title {
    font-size: 5vh !important;
    padding-top: 10vh !important;
    line-height: 1.1 !important;
    text-align: center;
    padding-right: 0px;
  }

  .tabsInnerContainer {
    flex-wrap: wrap;
  }

  .tabsInnerContainer>div {
    width: 100%;
  }

  .life_br {
    margin: 12px 0;
  }

  .locationWrapper {
    gap: 0 !important;
    line-height: 1.1 !important;
    text-align: center;
    padding-right: 0px;
  }

  .life_br {
    margin: 12px 0;
  }

  .locationWrapper {
    gap: 0 !important;
  }

  

  .position_righ_box {
    max-width: 100%;
  }

  .ourworkiteams>.workcard {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: calc(35% - 16px);
    max-width: calc(35% - 16px);
    width: calc(35% - 16px);
  }

  .prj_scrnshot,
  .prj_client_quote {
    width: 100%;
  }

  .prj_client_quote {
    margin-bottom: 20px;
    margin-top: 20px !important;
  }

  .position_dsc p {
    width: 100%;
  }

  .apply_form>div {
    width: 100%;
    text-align: left;
  }

  .graybox {
    padding: 0 8px 16px;
    width: calc(100% - 16px) !important;
  }
  .custselcontrol .MuiSelect-standard{
    width: 170px !important;
  }
  .workcard>div>div{
    overflow: hidden;
  }
  html.hide-scrollbar {
    margin-right: 0;
    overflow: hidden;
  }
  header.hide-scrollbar.headerbox {
    padding-right: 24px;
    /* width: 100% !important; */
  }
  .headerbox.scrolled.hide-scrollbar.headerbox {
    padding-right: 24px;
}
  .techno_icon, .techno_icon.inte_thumb{
    width: 300px;
  }
  .techno_icon.inte_thumb img{
    max-width: 100%;
  }
}

@media screen and (max-width:768px) {
  .casestudiesIteam {
    height: 100% !important;
  }
  .workcard::after{
    display: none;
  }
  .servicesLogo ul{
    gap: 14px;
  }
  .servicesLogo span img {
    height: auto;
    width: 100%;
}
  .ourworkiteams>.workcard{
    flex-basis: calc(34% - 16px);
    max-width: calc(34% - 16px);
    width: calc(34% - 16px);
    border: solid 1px var(--brand);
  }
  .ourworkiteams{gap:15px}
  .thank_msg h1{
    font-size: 28px;}
  .btnbrowse{width: 90px;}
  .BrowseFile p {padding-right: 90px;}

  .ourworkiteams>div {
    width: calc(49.5% - 20px);
  }

  .event-accordion {
    width: 100%;
    margin-left: 0;
    box-sizing: border-box;
    padding: 0;
  }
  .event_content{word-break: break-all;}
  .archivedTrainings > div > div > div{padding:15px !important;}
  .archivedTrainings {
    flex-wrap: wrap;
  }

  .archivedTrainings>div {
    width: 100%;
    flex: 0 1 auto;
  }
  .archivedTrainings > div:first-child {
    order: 1;
}

.ourworkiteams>.workcard .casestudies button{
  padding-left: 10px !important;
  padding-right: 10px !important;
  white-space: nowrap;
  width: auto !important;
}
.casestudiesIteam{border-bottom: none;}

}


@media screen and (max-width:767px) {
  .workcard>div, .workcard>div>div, .workcard>div>div>div{
    height: 99%;
  }
  ul.service-list li{
    width: 80%;
  }
  .position_list {
    padding: 12px 12px;
  }

  .position_btn span,
  .sharebtn span {
    width: 24px !important;
    height: 24px !important;
    font-size: 14px !important;
  }

  .position_btn,
  .sharebtn {
    font-size: 15px;
  }

  .showcase-btn {
    visibility: visible !important;
  }

  .posittion_details {
    font-size: 14px !important;
  }

 

  .position_list_title {
    margin-bottom: 8px !important;
    font-size: 16px !important;
  }

  .Title2 {
    font-size: 22px !important;
  }

  .contentpage p, .techno_content p,.techno_content a , ul.service-list li span{
    font-size: 14px;
    line-height: 24px;
  }

  .contentpage h2 {
    font-size: 18px !important;

  }

  .industries_title {
    margin-bottom: 15px !important;
  }

  .industries_title p {
    margin-bottom: 10px;
  }

  .heading_title {
    font-size: 4vh !important;
  }

  .position_righ_box {
    padding: 16px !important;
  }

  .secondheadingh5 {
    font-size: 20px !important;
    margin-bottom: 16px !important;
  }

  ul.service-list li {
    margin-bottom: 8px;
    font-size: 15px;
  }

  ul.service-list li span {
    font-size: 15px;
  }

  .address {
    font-size: 14px !important;
    line-height: 20px !important;
  }

  .filterInput {
    flex-direction: column !important;
    align-items: flex-start;
    width: 100%;
  }

  .resetFilterBtn {
    display: none !important;
  }

  .filterInput .SelectClass {
    margin: 0;
  }

  .filterInput label {
    margin: 0 8px;
  }

 
  .filterWrapper {
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    gap: 10px;
  }

  .page_intro {
    margin-bottom: 10px !important;
  }

  .filterInput>div {
    width: calc(100% - 16px);
  }

  .filterInput>div select {
    width: 100% !important;
    margin: 0;
  }

  .workcard::after {
    height: 0;
  }

  .ourworkiteams button {
    margin-bottom: 0 !important;
    margin-top: 0px !important;


  }

  .ourworkiteams {
    padding-left: 16px;
    padding-right: 16px;
    gap: 15px;
    margin-top: 20px;
  }

  .company_logo {
    width: 100%;
    text-align: center;
    height: auto;
  }

  .companydetails {
    flex-direction: column;
    width: 100%;
    font-size: 15px;
    gap: 6px;

  }

  .company_detail {
    padding: 0 !important;

  }

  .cmp_dtl_title {
    font-size: 20px !important;
  }

  .prjover-main-title,
  .contentpage h2.prjover-main-title {
    font-size: 22px !important;
    margin-bottom: 20px !important;
  }

  .prjover-title,
  .contentpage h6.prjover-title {
    font-size: 20px !important;
  }

  .client_quote_w span {
    font-size: 70px;
  }

  .client_quote_w span.left {
    left: 5px;
    top: 20px;

  }

  .csrow2_title,
  h5.csrow2_title {
    font-size: 20px !important;
  }

  .csc-content,
  .csc-content p {
    font-size: 15px;
    line-height: 20px;

  }

  .client_quote_w span.right {
    right: -8px;
    bottom: -20px;
  }

  .client_quote_w {
    padding: 10px 42px;
    font-size: 16px !important;
    line-height: 22px !important;
  }

  .prjover-content,
  .contentpage p.prjover-content p {
    font-size: 15px !important;
    line-height: 20px;
  }

  .position_dsc p b {
    width: 125px;
    font-size: 15px;
    line-height: 20px;
  }
  .companydetails a{
    margin-right: 0;
    display: inline-block;
  }
  .orangseperator {
    display: none;
}
.companydetails a:last-child {
  margin-bottom: 15px;
  display: inline-block;
}
.companydetails a{margin-bottom: 10px !important;}
.companydetails a.active{
  display: inline-block;
}

  .position_dsc p {
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 8px;
  }

  .position_dsc p span {
    width: calc(100% - 125px);
  }

  .position_details {
    padding: 0 !important;
  }

  .career-detail-page h5 {
    padding-left: 0;
  }

  #apply {
    padding: 0;
  }

  .expan_list,
  .apply_form_container {
    padding: 12px !important;
  }

  .jd ul li {
    padding-left: 15px;
  }

  .career-detail-page h5 {
    font-size: 24px;
  }

  .position_dsc {
    margin-bottom: 15px;
  }

  .apply_form label,
  .apply_form input[type=file] {
    font-size: 16px !important;
  }

  .expan_list,
  .apply_form_container {
    margin-bottom: 15px;
  }

  .filterInput .custselcontrol {
    margin: 0;
  }

  .event_title {
    font-size: 22px !important;
    line-height: 1.3 !important;
    margin: 15px 0 !important;
  }

  .prevNewEvent {
    margin-bottom: 16px !important;
    margin-top: 16px !important;
  }

  .pppage li {
    font-size: 15px !important;
    margin-left: 0px !important;
  }

  .pppage h4 {
    font-size: 18px !important;
    word-break: break-all;
  }

  .pppage h2 {
    font-size: 18px !important;
  }

  .pp_btmline {
    font-size: 15px !important;
    line-height: 20px !important;
  }

  .pb_customtab button {
    font-size: 15px !important;
    line-height: 20px !important;
    padding: 6px 8px;
    font-weight: 600 !important;
  }

  .tabsMainContent>div>div {
    padding: 20px 0 0;
  }

  .chatbotprod {
    height: auto;
  }

  .gridContainergrp {
    gap: 20px !important;
  }

  .custselcontrol .MuiSelect-standard {
    width: 100% !important;
  }

  .innerpagecontainer img {
    max-width: 100%;
  }
  .Technologies ul>li>div>img{
    height: 22px;
  }
}


@media screen and (max-width:767px) {
  .sharebtngroup {
    width: 100%;
    gap: 10px;
  }
}
@media screen and (max-width:640px) {
  .thank_msg h1{
    font-size: 24px;}
}
@media screen and (max-width:480px) {
  .companydetails ul{
    margin: 0 0 10px 0;
    flex-direction: column;
  }
  .thank_msg h1{
    font-size: 20px;}
  .companydetails ul li:first-child, .companydetails ul li {
    width: 100% !important;
    flex: 0;
    margin-bottom: 4px;
}
  .tooltip-content{
    width: 125px;
    margin: 0 0 10px 0px;
    bottom: inherit;
  }
  .tooltip-inner{
    border-bottom: none;
    border-top: solid 2px var(--brand);
  }
  .tooltip-content:after{
    left:12px;            top: -6px;    border-bottom-color: #d5452c;border-top: none;
  }
  .casestudiesIteam img{
    width: 80%;
  }
  .tooltip-content div ul {
    display: flex;
    flex-wrap: wrap;
}
  .Technologies ul>li>div>img{ 
      height: 26px;    
  }
  .ourworkiteams>div {
    width: 100%;
  }

  .joinourteam a {
    width: 240px;
  }

  .ourworkiteams>.workcard {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 100%;
    max-width: 100%;
    width: 100%;
  }

  .companydetails {
    width: 100%;
    font-size: 15px;
    flex-direction: column;
    gap: 6px;
  }

  .companydetails b {
    position: relative;
    width: 100%;
  }

  .companydetails>span,
  .companydetails>a {
    margin-left: 0;
  }

  .compnay_detail_div {
    margin-bottom: 0px;
  }

  .companydetails:last-child {
    margin-bottom: 0;
  }

  .spedate {
    font-size: 40px !important;
  }

  .spe_monthyear p {
    font-size: 16px !important;
  }

  .spe_loction p {
    margin-top: 20px;
  }

  .spe_date_grop {
    margin: 10px 0;
  }

  .position_btn p,
  .sharebtn p p {
    order: 1;
  }

  .position_btn span,
  .sharebtn span {
    margin-left: 0;
  }
  .OurServices .MuiGrid-container>div{
    flex-basis: 100%;
        max-width: 100%;
  }
}
@media screen and (max-width:400px) {
  .thank_msg h1{
    font-size: 20px;}
    .BlackbgTab .pb_customtab button{
      min-width: 100%;
    }
}

@media screen and (max-width:360px) {
  div#work {
    padding: 20px 0;
  }

  div#process {
    padding: 20px 0;
  }

  .hero-content {
    padding: 15px;
  }

  .joinourteam a {
    margin: 3px;
    margin-bottom: 30px;
    margin-top: 15px !important;
  }

  .jointeamheading {
    margin: 20px !important;
  }
}
 @media screen and (max-height: 630px){
  /* .BrowseFile p{    margin-top: 10px;        font-size: 15px;} */
  /* .apply_form>div, .BrowseFile{    margin: 0px 0 !important;} */
  .formstyles.contactform  label.MuiInputLabel-shrink , 
  .formstyles.contactform  label, 
  .formstyles.contactform  label.Mui-focused {
    transform: translate(0px, 0px) scale(1) !important;
    font-size: 15px !important;
    margin-top: 8px;
    z-index: 9;
  }
  .formstyles.contactform .formInput {
    margin: 0px 0 !important;
}
.formstyles.contactform  textarea {
  margin-top: 20px;
  height: 40px !important;
  color: #535353;
  font-size: 15px;
  font-family: 'ubuntulight';
}

.hero-content {
  margin-bottom: 50px;
}  
.formstyles.contactform input{
  color: #535353;
  font-size: 15px;
  font-family: 'ubuntulight';
  height: 40px;
  margin-top: 18px;
}
.formstyles.contactform  p.Mui-error, .formstyles.contactform p.Mui-warn{
  padding: 5px 10px;
  font-size: 13px;
}
} 