/* Custom font START */
@font-face {
  font-weight: normal;
  font-style: normal;
  font-family: "icomoon";
  src: url("../public/font/icomoon/icomoon.eot");
  src:
    url("../public/font/icomoon/icomoon.eot?#iefix") format("embedded-opentype"),
    url("../public/font/icomoon/icomoon.ttf") format("truetype"),
    url("../public/font/icomoon/icomoon.woff") format("woff"),
    url("../public/font/icomoon/icomoon.svg#icomoon") format("svg");
}

@font-face {
  font-weight: normal;
  font-style: normal;
  font-family: "icomoon";
  src: url("../public/font/icomoon/icomoon.eot");
  src:
    url("../public/font/icomoon/icomoon.eot?#iefix") format("embedded-opentype"),
    url("../public/font/icomoon/icomoon.ttf") format("truetype"),
    url("../public/font/icomoon/icomoon.woff") format("woff"),
    url("../public/font/icomoon/icomoon.svg#icomoon") format("svg");
}

@font-face {
  font-family: "ubuntulight";
  src: url("../public/font/ubuntu-l-webfont.eot");
  src:
    url("../public/font/ubuntu-l-webfont.eot?#iefix") format("embedded-opentype"),
    url("../public/font/ubuntu-l-webfont.woff2") format("woff2"),
    url("../public/font/ubuntu-l-webfont.woff") format("woff"),
    url("../public/font/ubuntu-l-webfont.ttf") format("truetype"),
    url("../public/font/ubuntu-l-webfont.svg#ubuntulight") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "ubunturegular";
  src: url("../public/font/ubuntu-r-webfont.eot");
  src:
    url("../public/font/ubuntu-r-webfont.eot?#iefix") format("embedded-opentype"),
    url("../public/font/ubuntu-r-webfont.woff2") format("woff2"),
    url("../public/font/ubuntu-r-webfont.woff") format("woff"),
    url("../public/font/ubuntu-r-webfont.ttf") format("truetype"),
    url("../public/font/ubuntu-r-webfont.svg#ubunturegular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "ubuntumedium";
  src: url("../public/font/ubuntu-m-webfont.eot");
  src:
    url("../public/font/ubuntu-m-webfont.eot?#iefix") format("embedded-opentype"),
    url("../public/font/ubuntu-m-webfont.woff2") format("woff2"),
    url("../public/font/ubuntu-m-webfont.woff") format("woff"),
    url("../public/font/ubuntu-m-webfont.ttf") format("truetype"),
    url("../public/font/ubuntu-m-webfont.svg#ubuntumedium") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "ubuntubold";
  src: url("../public/font/ubuntu-b-webfont.eot");
  src:
    url("../public/font/ubuntu-b-webfont.eot?#iefix") format("embedded-opentype"),
    url("../public/font/ubuntu-b-webfont.woff2") format("woff2"),
    url("../public/font/ubuntu-b-webfont.woff") format("woff"),
    url("../public/font/ubuntu-b-webfont.ttf") format("truetype"),
    url("../public/font/ubuntu-b-webfont.svg#ubuntubold") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "ubuntuitalic";
  src:
    url("../public/font/ubuntu-ri-webfont.woff2") format("woff2"),
    url("../public/font/ubuntu-ri-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}