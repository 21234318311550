
/*Define variable*/
@import url("customfonts.css");

:root {
  --brand: #d4503a;
  --white: #ffffff;
  --black: #000000;
  --darlgray: #323232;
  --ubunturegular: "ubunturegular", sans-serif;
  --ubuntumedium: "ubuntumedium", sans-serif;
  --ubuntulight: "ubuntulight", sans-serif;
  /* --ubuntulight: "ubunturegular", sans-serif; */
  --ubuntubold: "ubuntubold", sans-serif;
  --ubuntuitalic: "ubuntuitalic", sans-serif;
  --bodycontentfontsizelg: 21px;
  --heading: 37px;
  --secondheading: 24px;
  --border-radius: 20px;
  --gap: 40px;
  --spacebetweenlg: 30px;
  --variant-containedBg: #d4503a !important;
  --orangetitle:30px;
}